<template>
  <div>
    <b-card>
      <h5 v-if="!isModal">
        Withdraw From {{ wallet.description }}
      </h5>
      <div class="mb-2">
        <h5>{{ $t('Available Balance') }}: {{ formatAmount(Math.floor(wallet.balance * 100) / 100) }}</h5>
        <h5>{{ $t('Minimum Withdrawal') }}: {{ formatAmount(10 + getTransferFee) }}</h5>
      </div>

      <b-overlay :show="isProcessing">
        <validation-observer ref="withdrawFundForm">
          <!-- Amount to Withdraw -->
          <validation-provider
            #default="{errors}"
            name="amount"
            :rules="`required|min_value:${10 + getTransferFee}|max_value:${(Math.floor(wallet.balance * 100) / 100).toFixed(2)}`"
            :custom-messages="{ max_value: 'You have insufficient balance' }"
          >
            <b-form-group :label="$t('Amount')">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="form.amount"
                  type="number"
                  placeholder="Amount to Withdraw"
                  :disabled="action === 'deactivate'"
                  @keypress="handleAmountInput($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>

              <div
                v-if="getTransferFee > 0"
                class="text-warning"
              >
                <small>
                  {{ `*${walletType} transfer fee of ${formatAmount(getTransferFee)} will be deducted from your withdrawal amount` }}
                </small>
              </div>
            </b-form-group>
          </validation-provider>

          <!-- Withdrawal Reason -->
          <validation-provider
            #default="{ errors }"
            name="withdrawal narration"
            rules="required"
          >
            <b-form-group :label="$t('Withdrawal Narration')">
              <b-form-input
                v-model="form.reason"
                placeholder="Eg. Salary"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>

        <h5
          v-if="form.amount >= 10 + getTransferFee && form.amount <= wallet.balance"
          class="text-right"
        >
          <feather-icon
            icon="InfoIcon"
            size="16"
          />
          {{ $t('Amount you will receive') }}: {{ formatAmount(form.amount - getTransferFee) }}
        </h5>
      </b-overlay>

      <!-- Buttons -->
      <div class="d-flex justify-content-end mt-2">
        <b-button
          class="mx-1"
          :disabled="isProcessing"
          @click="() => $emit('back')"
        >
          {{ $t('Cancel') }}
        </b-button>

        <span v-b-tooltip.hover="form.amount > wallet.balance ? $t('Insufficient Balance') : ''">
          <b-button
            variant="primary"
            :disabled="isProcessing || form.amount > wallet.balance"
            @click="showConfirmModal"
          >
            <i class="fa fa-circle-dollar-to-slot" />
            {{ $t('Withdraw') }}
          </b-button>
        </span>
      </div>
    </b-card>

    <!-- Withdrawal Confirm Modal -->
    <b-modal
      id="withdraw-fund-modal"
      :title="$t('Confirm Fund Withdrawal')"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      no-close-on-backdrop
      @ok="withdrawFund"
    >
      <p class="mb-3">
        {{ `${formatAmount(form.amount)} will be deducted from ${wallet.description}. Do you want to confirm your withdrawal?` }}
      </p>
      <div>
        <div class="d-flex justify-content-between">
          <p>{{ $t('Withdrawal Amount') }}:</p>
          <h5>{{ formatAmount(form.amount) }}</h5>
        </div>
        <div class="d-flex justify-content-between">
          <p>{{ walletType }} {{ $t('Transfer Fee') }}:</p>
          <h5 class="text-danger">
            -{{ formatAmount(charges.id ? charges[chargeType] : 0) }}
          </h5>
        </div>
        <div class="d-flex justify-content-between">
          <p>{{ $t('You will receive') }}:</p>
          <h5>{{ formatAmount(form.amount - (charges.id ? charges[chargeType] : 0)) }}</h5>
        </div>
      </div>

      <small class="text-warning">
        *{{ getChargesInfo }}
      </small>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BFormGroup, BButton, BOverlay, BModal, BInputGroup, BInputGroupText, VBTooltip,
} from 'bootstrap-vue'
import {
  ValidationObserver, ValidationProvider, extend,
} from 'vee-validate'
import { required, max_value, min_value } from 'vee-validate/dist/rules'
import useApollo from '@/plugins/graphql/useApollo'
import RadioForm from '@/views/common/components/RadioForm.vue'

extend('wz-double', value => {
  if (typeof value !== 'number') {
    const decimals = value.split('.')[1]
    if (decimals && decimals.length > 2) return 'The amount should have atmost 2 decimal points'
  }
  return true
})

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupText,
    BInputGroup,
    BButton,
    BOverlay,
    BModal,
    RadioForm,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    wallet: {
      type: Object,
      default: () => {},
    },
    parentWallet: {
      type: String,
      default: () => '',
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    isModal: {
      type: Boolean,
      default: () => false,
    },
    charges: {
      type: Object,
      default: () => {},
    },
    action: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      required,
      max_value,
      min_value,
      form: {
        reason: '',
        amount: null,
      },
      isProcessing: false,
      company: this.$store.state.project.selectedCompany,
      chargeType: 'swiftCharge',
      walletType: 'Swift',
    }
  },
  computed: {
    getChargesInfo() {
      switch (this.wallet.wallet.information.__typename) {
        case 'AchBillingInformation':
          return this.$t('messages.ach-fee-info')
        case 'FxBillingInformation':
          return ''
        default:
          return this.$t('messages.swift-fee-info')
      }
    },
    getTransferFee() {
      return this.charges[this.chargeType] ?? 0
    },
  },
  watch: {
    action: {
      handler(val) {
        if (val && this.wallet) {
          this.form.amount = this.wallet.balance
          this.isProcessing = false
        }
      },
      immediate: true,
    },
    'form.amount': {
      handler(val) {
        if (val) {
          this.form.amount = Number(this.form.amount)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.action === 'deactivate') {
      this.isProcessing = true
      setTimeout(() => { this.isProcessing = false }, 500)
    }
    if (this.wallet) {
      switch (this.wallet.wallet.information.__typename) {
        case 'AchBillingInformation':
          this.chargeType = 'achCharge'
          this.walletType = 'ACH'
          break
        case 'FxBillingInformation':
          this.chargeType = 'fxCharge'
          this.walletType = 'Fx'
          break
        default:
          this.chargeType = 'swiftCharge'
          this.walletType = 'Swift'
      }
    }
  },
  methods: {
    handleAmountInput(event) {
      const inputValue = event.target.value
      const decimalParts = inputValue.split('.')

      if (decimalParts.length > 1 && decimalParts[1].length > 1) {
        event.preventDefault()
      }
      this.form.amount = event.target.value
    },
    showConfirmModal() {
      this.$refs.withdrawFundForm.validate().then(success => {
        if (success) {
          this.$bvModal.show('withdraw-fund-modal')
        }
      })
    },
    withdrawFund() {
      this.isProcessing = true
      const params = {
        wallet: {
          walletUid: this.parentWallet,
          shareUid: this.wallet.uuid,
        },
        input: {
          amount: Number(this.form.amount),
          description: this.form.reason,
        },
      }
      let api = 'withdrawFromBankWallet'

      if (this.userType === 'company') {
        params.companyUid = this.company
        api = 'withdrawFromCompanyBankWallet'
      }

      useApollo[this.userType][api](params).then(response => {
        setTimeout(() => {
          this.showSuccessMessage({
            data: {
              message: response.data[api].message,
            },
          })
        }, 5000)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        setTimeout(() => {
          this.$emit('withdraw-success')
          this.isProcessing = false
        }, 5000)
      })
    },
  },
}
</script>
